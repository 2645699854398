import Css from "./style.module.scss";

import { Select } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useMemo } from "react";

const ChangeStatusSelector = ({ statusesList, excludedStatuses = [], ...restProps }) => {
  const { uiTexts } = useSelector(getTextsData);

  const options = useMemo(() => {
    const assignableStatuses = statusesList.filter(
      ({ value, canBeAssigned }) => !excludedStatuses.includes(value) && canBeAssigned
    );

    return assignableStatuses.map(({ value, labelLangId, iconComponent: Icon }) => ({
      value,
      label: (
        <div className={Css.item}>
          <Icon data-status={value} />
          <span>{uiTexts[labelLangId]}</span>
        </div>
      )
    }));
  }, [excludedStatuses, statusesList, uiTexts]);

  return (
    <Select
      placeholder={`${uiTexts.moveThemTo}...`}
      options={options}
      {...restProps} />
  );
};

export default React.memo(ChangeStatusSelector);
